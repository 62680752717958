<template>
  <div class="accordion-actions pb-3">
    <div v-if="data.retryable">
      <p class="text-dark-darken">
        <small>{{ $t('messages.retryDelivery') }}</small>
      </p>
      <uello-button class="min-width" color="red" size="small" @click="retryOrder">
        <span v-if="!loading">
          {{ $t('orderEditModals.retryDelivery') | capitalize }}
        </span>
        <Loading :height="18" :width="18" v-else />
      </uello-button>
    </div>

    <div v-if="data.returnable">
      <p class="text-dark-darken mt-3">
        <small>{{ $t('messages.returnOrder') }}</small>
      </p>
      <uello-button class="min-width" color="red" size="small" @click="returnOrder">
        <span v-if="!loading">
          {{ $t('orderEditModals.returnOrder') | capitalize }}
        </span>
        <Loading :height="18" :width="18" v-else />
      </uello-button>
    </div>

    <div v-if="data.deletable">
      <p class="text-dark-darken mt-3">
        <small>{{
          $t('messages.cancelOrderSolicitation', { operation: $t(`operations.${data.operation}`) })
        }}</small>
      </p>
      <uello-button class="min-width" color="red" size="small" @click="cancelOrder">
        <span v-if="!loading">
          {{ $t('orderEditModals.cancelOrder') | capitalize }}
        </span>
        <Loading :height="18" :width="18" v-else />
      </uello-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UelloButton } from '@uello/componentello';
import * as types from '@/store/types';
import api from '@/services/api';
import Loading from '@/components/Loading.vue';

export default {
  name: 'accordion-actions',
  props: {
    data: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  components: {
    Loading,
    UelloButton,
  },
  computed: {
    ...mapGetters({
      modal: types.GETTER_MODAL,
    }),
    loadingConfrontation() {
      return this.modal.loading;
    },
    confirmed() {
      return this.modal.confirmed;
    },
    isDisabled() {
      if (this.order.state !== 'entregue') {
        return {
          status: true,
          message: 'Atenção, só é possível solicitar acareação para pedidos finalizados.',
        };
      }
      if (this.order.confrontation) {
        return {
          status: true,
          message: `Atenção - Fora do Prazo Acareação de até ${this.order.confrontation.days} dias após Prazo de Entrega`,
        };
      }
      return { status: false };
    },
  },
  watch: {
    confirmed(val) {
      if (val) {
        this.confrontationRequest();
      }
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async returnOrder() {
      this.loading = true;
      try {
        const { data } = await api.put(`orders/${this.data.id}/return`);

        this.$toast.success(data.message);
      } catch (error) {
        this.$toast.error(this.$t('messages.returnOrderFail'));
      }
      this.loading = false;
    },
    async retryOrder() {
      this.loading = true;
      try {
        const { data } = await api.put(`orders/${this.data.id}/retry`);

        this.$toast.success(data.message);
      } catch (error) {
        this.$toast.error(this.$t('messages.rettryOrderFail'));
      }
      this.loading = false;
    },
    async cancelOrder() {
      this.loading = true;
      try {
        await api.delete(`orders/${this.data.id}?document=${this.data.document}`);

        this.$toast.success(
          this.$t('messages.cancelOrder', {
            operation: this.$t(`operations.${this.data.operation}`),
          })
        );
        this.$emit('cancel-order', this.data.id);
      } catch (error) {
        this.$toast.error(this.$t('messages.cancelOrderFail'));
      }
      this.loading = false;
    },

    confimRecoveryRequest() {
      this.$store.commit('@orders/MUTATE_MODAL', {
        visible: true,
        text: 'Você confirma a abertura da acareação? Essa ação não poderá ser desfeita',
        type: 'confirm',
        confirmed: false,
      });
      return true;
    },

    confrontationRequest() {
      api
        .post('order/recovery', {
          order: this.data.id,
          partner_user: 1,
        })
        .then(() => {
          this.$store.commit('@orders/MUTATE_MODAL', {
            confirmed: false,
            text:
              'Sua solicitação de acareação foi criada com sucesso. Por gentileza agora prossiga com a abertura de ticket com mais informações para nossa equipe entrar em contato.',
            visible: true,
            type: 'success',
            model: 'success',
            loading: false,
          });
          setTimeout(() => {
            const ticketButton = document.getElementById('open-ticket');
            ticketButton.click();
          }, 3000);
        })
        .catch(() => {
          this.$store.commit('@orders/MUTATE_MODAL', {
            confirmed: false,
            text:
              'Houve uma instabilidade no sistema. Por gentileza abra sua solicitação <strong>no botão do Movidesk abaixo.</strong>',
            visible: true,
            type: 'error',
            model: 'error',
            loading: false,
          });
        });
    },
  },
};
</script>

<style lang="scss">
.accordion-actions {
  button.min-width {
    min-width: 146px;
  }
}
</style>
